import ReactDOM from 'react-dom/client';

function importBuildTarget() {
  if (process.env.REACT_APP_BUILD_TARGET === 'stand-alone') {
    return import('./stand-alone-app');
  } else if (process.env.REACT_APP_BUILD_TARGET === 'shopify') {
    return import('./shopify-app');
  } else if (process.env.REACT_APP_BUILD_TARGET === 'clients') {
    return import('./creator-apps');
  } else {
    return Promise.reject(new Error('No such build target: ' + process.env.REACT_APP_BUILD_TARGET));
  }
}

// Import the entry point and render it's default export
const root = ReactDOM.createRoot(document.getElementById('root'));
importBuildTarget().then(({ default: Environment }) => {
  root.render(<Environment />);
});
