import React from 'react';

import { DEVICES, HSL, hsl2hex, useDeviceDetect, useMediaQuery } from '@utils';

import { Box } from '@chakra-ui/react';

export function ColorWheelInput(props: {
  value: number;
  onChange: (value: number) => void;
  hsl: HSL;
  style: React.CSSProperties;
  breakpoint?: string;
}) {
  const device = useDeviceDetect();
  const isLarge = useMediaQuery(props.breakpoint || '750px');
  const canvas = React.useRef<HTMLCanvasElement>(null);
  const scale = 4;

  const [startPos, setStartPos] = React.useState<[x: number, y: number]>([0, 0]);
  const [isDragging, setDragging] = React.useState(false);
  const [delta, setDelta] = React.useState(0);
  const [rotation, setRotation] = React.useState(props.value / 360);

  const onStartDrag = React.useCallback(
    (e: MouseEvent | TouchEvent) => {
      const client = e.type === 'touchstart' ? (e as TouchEvent).touches[0] : (e as MouseEvent);
      setStartPos([client.clientX, client.clientY]);
      setRotation(props.value / 360);
      setDragging(true);
      setDelta(0);
    },
    [delta, device, props.value, setRotation, setDragging, setDelta],
  );
  const onMoveDrag = React.useCallback(
    (e: MouseEvent | TouchEvent) => {
      if (!isDragging) return;
      const isMobile = device === DEVICES.MOBILE;

      let d = 0;
      const factor = isMobile ? 0.25 : 1;
      if (e.type === 'touchmove') {
        d = -(startPos[0] - (e as TouchEvent).touches[0].clientX) / (700 / 2);
      } else {
        const el = canvas.current;
        if (!el) return;

        const center = [el.offsetLeft + el.width / 2, el.offsetTop + el.height / 2];
        const orgAngle = Math.atan2(startPos[1] - center[1], startPos[0] - center[0]);
        const newAngle = Math.atan2((e as MouseEvent).clientY - center[1], (e as MouseEvent).clientX - center[0]);
        d = newAngle - orgAngle;
      }
      setDelta(d);

      const r = (rotation + d * factor + 2) % 1;
      props.onChange(Math.round(r * 360));
    },
    [rotation, isLarge, delta, startPos, device, isDragging, canvas],
  );
  const onStopDrag = React.useCallback((e: MouseEvent | TouchEvent) => {
    setDragging(false);
  }, []);

  React.useEffect(() => {
    let el = document.getElementById('slider-wheel');
    if (!el) el = document.getElementById('root');
    if (!el) throw new Error('Sliding element could not be found');

    el.addEventListener('touchstart', onStartDrag);
    el.addEventListener('touchmove', onMoveDrag);
    el.addEventListener('mousedown', onStartDrag);
    el.addEventListener('mousemove', onMoveDrag);
    window.addEventListener('touchend', onStopDrag);
    window.addEventListener('mouseup', onStopDrag);

    return () => {
      if (!el) return;
      el.removeEventListener('touchstart', onStartDrag);
      el.removeEventListener('touchmove', onMoveDrag);
      el.removeEventListener('mousedown', onStartDrag);
      el.removeEventListener('mousemove', onMoveDrag);
      window.removeEventListener('touchend', onStopDrag);
      window.removeEventListener('mouseup', onStopDrag);
    };
  }, [device, onStartDrag, onMoveDrag, onStopDrag]);

  React.useEffect(() => {
    if (!canvas.current) return;
    const ctx = canvas.current.getContext('2d');
    if (!ctx) return;
    ctx.clearRect(0, 0, canvas.current.width, canvas.current.height);

    const colors: string[] = [];
    for (let i = 0; i < 361; i += 5) colors.push(hsl2hex(i, props.hsl[1], props.hsl[2]));
    colors.reverse();

    const xc = 150 * scale,
      yc = 150 * scale,
      r = 130 * scale;
    const partLength = (2 * Math.PI) / colors.length;

    let start = 0;
    let gradient = null;
    let startColor = null;
    let endColor = null;

    ctx.beginPath();
    ctx.arc(xc, yc, r, 0, Math.PI * 2);
    ctx.fillStyle = 'white';
    ctx.fill();
    ctx.closePath();

    for (let i = 0; i < colors.length; i++) {
      startColor = colors[i];
      endColor = colors[(i + 1) % colors.length];

      // x start / end of the next arc to draw
      const xStart = xc + Math.cos(start) * r;
      const xEnd = xc + Math.cos(start + partLength) * r;
      // y start / end of the next arc to draw
      const yStart = yc + Math.sin(start) * r;
      const yEnd = yc + Math.sin(start + partLength) * r;

      ctx.beginPath();

      gradient = ctx.createLinearGradient(xStart, yStart, xEnd, yEnd);
      gradient.addColorStop(0, startColor);
      gradient.addColorStop(1.0, endColor);

      ctx.strokeStyle = gradient;
      ctx.arc(xc, yc, r, start, start + partLength + 0.01);
      ctx.lineWidth = !isLarge ? 10 * scale : 40 * scale;
      ctx.stroke();
      ctx.closePath();

      start += partLength;
    }
  }, [canvas, props.hsl, isLarge]);

  return (
    <Box
      id='slider-wheel'
      aria-label='slider-wheel'
      style={props.style}
      cursor='pointer'
      w='100%'
      overflow='hidden'
      h={{ base: '100%', web: 'auto' }}
      bottom={{ base: '0', web: 'unset' }}
      position='relative'
      pt='5vh'
      _after={{
        base: {
          content: `"Spin the wheel and select a hue."`,
          fontSize: '1.5rem',
          position: 'absolute',
          width: '100%',
          pointerEvents: 'none',
          textAlign: 'center',
          left: '0',
          bottom: '1rem',
          zIndex: 2,
        },
        web: {
          bottom: '50% !important',
          fontSize: '1vw !important',
          transformOrigin: 'center',
          transform: 'translateY(150%)',
        },
      }}
    >
      <canvas
        aria-label='slider'
        ref={canvas}
        width={300 * scale}
        height={300 * scale}
        style={
          isLarge
            ? {
                position: 'relative',
                maxWidth: 'Min(500px, 25vw)',
                width: '100%',
                height: 'auto',
                top: '0%',
                left: '50%',
                transformOrigin: 'center',
                transform: `translateX(-50%) rotate(${props.value - 90}deg)`,
              }
            : {
                position: 'absolute',
                top: '40%',
                left: '50vw',
                transformOrigin: 'center',
                transform: `translateX(-50%) translateY(-50px) rotate(${props.value - 90}deg)`,
                width: '300vw',
                height: '300vw',
              }
        }
      />
      <span
        aria-label='indicator'
        style={{
          position: 'absolute',
          display: 'block',
          borderRadius: '50%',
          backgroundColor: hsl2hex(...props.hsl),
          left: '50%',
          transform: 'translate(-50%, -50%)',
          border: '5px solid #f2f2f2',
          boxShadow: '0px 2px 5px 2px rgba(0, 0, 0, 0.2)',
          top: isLarge ? '5vh' : undefined,
          bottom: isLarge ? undefined : '12vw',
          height: isLarge ? '5vw' : '60px',
          width: isLarge ? '5vw' : '60px',
        }}
      />
    </Box>
  );
}
