import React from 'react';
import { VIEWS, GoToViewFunc, DependSession } from '..';
import { validateEmail } from '@utils';

import { useSession } from '@services/session';
import * as API from '@utils/api';

import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import { LayoutWrapper, Swatch } from '@components';

const logo = require('@assets/media/lilly-nails-logo.png');

export default function EndView(props: { goToView: GoToViewFunc; shade: string }) {
  const { session, resetSession, setError } = useSession<DependSession>();

  const [isLoading, setLoading] = React.useState(false);
  const [isSubmitted, setSubmitted] = React.useState(false);

  const [name, setName] = React.useState({ touched: false, isValid: false, value: '' });
  const [email, setEmail] = React.useState({ touched: false, isValid: false, value: '' });

  const shade = session.get('cc');

  const submit = React.useCallback(() => {
    if (isSubmitted) return;

    if (!name.isValid) {
      setName({ touched: true, isValid: false, value: name.value });
      return;
    }

    if (!email.isValid) return;

    setLoading(true);
    API.createProduct({
      body: {
        content: [
          {
            type: 'nailgel',
            derivedFrom: {
              expectedShade: props.shade,
            },
          },
        ],
        metadata: {
          email: email.value,
          name: name.value,
          orderDate: new Date().toJSON(),
        },
      },
    })
      .then(() => {
        setSubmitted(true);
      })
      .catch((e) => {
        console.log(e);
        setError('An error occurred while submitting your product. Please try it later again.');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [name, email, isSubmitted, props.shade]);

  React.useEffect(() => {
    if (isSubmitted) setTimeout(resetSession, 10 * 1000);
  }, [isSubmitted]);

  return (
    <LayoutWrapper
      style={{
        background:
          'linear-gradient(0deg, rgba(217,104,104,0.2) 0%, rgba(231,156,156,0.15) 15%, rgba(245,208,208,0.10) 35%, rgba(255,255,255,0) 50%)',
      }}
    >
      <Grid templateAreas={`"body"`} templateColumns={'100%'} templateRows={'1fr'} gap='0 5vw' height='100%' flex='1'>
        <GridItem area='body' textAlign='center' alignSelf={'center'} justifySelf='center'>
          {isSubmitted ? (
            <>
              <Flex flexDir='column' alignItems={'center'} gap={6} maxWidth={'600px'} mb={'15em'}>
                <Image src={logo} alt='Lilly Nails' width={'100px'} />
                <Heading as='h1'>You are all set!</Heading>
                <Text>Your nail artist will now start the robot to make your personal shade!</Text>
              </Flex>
            </>
          ) : (
            <>
              <Flex flexDir='column' alignItems={'center'} gap={6} maxWidth={'600px'}>
                <Image src={logo} alt='Lilly Nails' width={'100px'} />
                <Heading as='h1'>Almost there… we only need to know your name and email</Heading>
                <Flex gap={10} mb={10}>
                  <Flex flexDir='column'>
                    <Swatch shade={shade || 'grey'} isChecked={!!shade} width='160px' />
                  </Flex>
                  <Stack spacing={5}>
                    <FormControl
                      maxW={'500px'}
                      w={'400px'}
                      isDisabled={isLoading || isSubmitted}
                      isRequired
                      isInvalid={name.touched && !name.isValid}
                    >
                      <FormLabel>Shade name</FormLabel>
                      <Input
                        value={name.value}
                        background={'whiteAlpha.900'}
                        onChange={(e) =>
                          setName({ touched: true, isValid: e.target.value !== '', value: e.target.value })
                        }
                      />
                      <FormErrorMessage>Name cannot be empty.</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      maxW={'500px'}
                      isDisabled={isLoading || isSubmitted}
                      isInvalid={email.touched && !email.isValid}
                    >
                      <FormLabel>Your email</FormLabel>
                      <Input
                        value={email.value}
                        background={'whiteAlpha.900'}
                        type='email'
                        placeholder='your@email.se'
                        onChange={(e) =>
                          setEmail({
                            touched: true,
                            isValid: e.target.value === '' || !!validateEmail(e.target.value),
                            value: e.target.value,
                          })
                        }
                      />
                      <FormErrorMessage>Email is invalid.</FormErrorMessage>
                    </FormControl>
                  </Stack>
                </Flex>
                <Button
                  size='lg'
                  colorScheme='depend'
                  isDisabled={isLoading || isSubmitted}
                  isLoading={isLoading}
                  onClick={submit}
                >
                  Submit shade
                </Button>
              </Flex>
              <Divider my={6} />
              <Button
                size='lg'
                background={'whiteAlpha.600'}
                variant={'outline'}
                isDisabled={isLoading || isSubmitted}
                onClick={props.goToView.bind(null, VIEWS.CONFIRM)}
              >
                Back
              </Button>
            </>
          )}
        </GridItem>
      </Grid>
    </LayoutWrapper>
  );
}
