import { useCallback, useRef } from 'react';

// Define the types for the useThrottle hook
export function useThrottledCallback<T extends (...args: any[]) => void>(callback: T, delay: number, deps: React.DependencyList) {
  // useRef to track the last call time and the timeout ID
  const lastCallTimeRef = useRef<number | null>(null);
  const timeoutRef = useRef<number | null>(null);

  // Create a throttled version of the callback function
  const throttledCallback = useCallback(
    (...args: Parameters<T>) => {
      const now = Date.now();

      if (lastCallTimeRef.current === null || now - lastCallTimeRef.current >= delay) {
        // If the last call was long enough ago, invoke the callback immediately
        callback(...args);
        lastCallTimeRef.current = now;
      } else {
        // Otherwise, set a timeout to invoke it after the delay
        if (timeoutRef.current !== null) {
          clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = window.setTimeout(
          () => {
            callback(...args);
            lastCallTimeRef.current = Date.now();
          },
          delay - (now - lastCallTimeRef.current),
        );
      }
    },
    [callback, delay, ...deps],
  );

  return throttledCallback;
}
