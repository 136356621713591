import React from 'react';
import { VIEWS, GoToViewFunc, Shades, DependSession } from '..';
import { Pallete } from '@utils/api';

import { useSession } from '@services/session';
import { hex2hsl, hsl2hex, remap } from '@utils';
import * as API from '@utils/api';

import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Grid,
  GridItem,
  Heading,
  Spacer,
  Spinner,
  Text,
  useRadioGroup,
} from '@chakra-ui/react';
import { Swatch } from '@components';
import { ColorSliderInput } from '@components/creator/color-slider';

export default function ChooseShadeView(props: { goToView: GoToViewFunc; base: string; shade: string | null }) {
  const { session, updateSession } = useSession<DependSession>();

  const [lightness, setLightness] = React.useState(
    parseInt(session.get('l') || hex2hsl(props.base)[2].toString() || '50'),
  );
  const [vibrance, setVibrance] = React.useState(parseInt(session.get('v') || '10'));

  const selectedShade = session.get('c');

  const [list, setList] = React.useState<{ pallete: Pallete; vibrance: number } | null>(null);
  const shadesGroup = useRadioGroup({
    name: 'shade',
    defaultValue: selectedShade === null ? undefined : selectedShade,
  });

  // UPDATE SHADE VALUES
  React.useEffect(() => {
    updateSession({
      c: shadesGroup.value ? shadesGroup.value : undefined,
      v: vibrance,
      l: lightness,
    });
  }, [shadesGroup.value, lightness, vibrance]);

  // UPDATE SHADE LIST
  React.useEffect(() => {
    API.getPallete({
      body: {
        shadeId: props.base || Shades.bases[0], //'e7206915-3b48-4d4b-8500-82958c564cf2',
        tintStep: vibrance,
        tempStep: vibrance,
      },
      query: { product: 'nailgel' },
    })
      .then((response) => {
        if (list) {
          const value = shadesGroup.value;
          let newPallete = response.data.neutral;
          let index = 3;
          for (const [key, pallete] of Object.entries(list.pallete)) {
            const j = pallete.findIndex((s) => s === value);
            if (j > -1) {
              index = j;
              newPallete = response.data[key as 'cold' | 'neutral' | 'warm'];
            }
          }
          shadesGroup.setValue(newPallete[index]);
        } else if (selectedShade === null) {
          shadesGroup.setValue(response.data.neutral[3]);
        }

        setList({
          pallete: response.data,
          vibrance: vibrance,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }, [vibrance, props.base, selectedShade]);

  const hsl = hex2hsl((shadesGroup.value as string) || props.base);
  const hslBase = hex2hsl(props.base);

  return (
    <>
      <GridItem area='body' textAlign='center'>
        <Grid
          templateAreas={`"header" "picker"`}
          templateColumns={'100%'}
          templateRows={'max-content 1fr'}
          gap='0 5vw'
          height='100%'
          flex='1'
        >
          <GridItem p={3} area='header' alignSelf={'stretch'}>
            <Heading as='h1'>Design your shade</Heading>
          </GridItem>
          <GridItem p={3} area='picker' alignSelf={'center'}>
            <Flex p={3} flex='1' gap='1.5rem 3%' flexWrap='wrap' alignItems='center' alignContent='center'>
              <Text mt={3} w='100%'>
                Shade hue
              </Text>
              {list &&
                list.pallete['cold'].map((shade, index) => {
                  const _hsl = hex2hsl(shade);
                  return (
                    <Swatch
                      key={index}
                      size='sm'
                      shade={hsl2hex(_hsl[0], _hsl[1], lightness)}
                      {...shadesGroup.getRadioProps({ value: shade })}
                    />
                  );
                })}
              {list &&
                list.pallete['neutral'].map((shade, index) => {
                  const _hsl = hex2hsl(shade);
                  return (
                    <Swatch
                      key={index}
                      size='sm'
                      shade={hsl2hex(_hsl[0], _hsl[1], lightness)}
                      {...shadesGroup.getRadioProps({ value: shade })}
                    />
                  );
                })}
              {list &&
                list.pallete['warm'].map((shade, index) => {
                  const _hsl = hex2hsl(shade);
                  return (
                    <Swatch
                      key={index}
                      size='sm'
                      shade={hsl2hex(_hsl[0], _hsl[1], lightness)}
                      {...shadesGroup.getRadioProps({ value: shade })}
                    />
                  );
                })}
              {!list && (
                <Flex w='100%' justifyContent='center'>
                  <Spinner size='lg' />
                </Flex>
              )}
            </Flex>
            <Box p={3}>
              <Text mt={3}>Vibrance</Text>
              <ColorSliderInput
                colorKeys={[
                  hsl2hex(hslBase[0], 40, lightness),
                  hsl2hex(hslBase[0], 70, lightness),
                  hsl2hex(hslBase[0], 100, lightness),
                ]}
                domain={[4, 16, 2]}
                showLabel={false}
                label=''
                value={vibrance}
                onChange={(value) => setVibrance(value)}
                hsl={[hslBase[0], remap(vibrance, 4, 16, 40, 100), lightness]}
              />
            </Box>
            <Box p={3}>
              <Text mt={3}>Lightness</Text>
              <ColorSliderInput
                colorKeys={[hsl2hex(hsl[0], hsl[1], 20), hsl2hex(hsl[0], hsl[1], 50), hsl2hex(hsl[0], hsl[1], 80)]}
                domain={[20, 80, 1]}
                showLabel={false}
                label=''
                value={lightness}
                onChange={(value) => setLightness(value)}
                hsl={[hsl[0], hsl[1], lightness]}
              />
            </Box>
          </GridItem>
        </Grid>
      </GridItem>
      <GridItem area='nav' textAlign='center'>
        <ButtonGroup p={3} spacing={4}>
          <Button
            size='lg'
            variant={'outline'}
            background={'whiteAlpha.600'}
            onClick={props.goToView.bind(null, VIEWS.CHOOSE_BASE)}
          >
            Back
          </Button>
          <Spacer />
          <Button size='lg' colorScheme='depend' onClick={props.goToView.bind(null, VIEWS.CONFIRM)}>
            Next
          </Button>
        </ButtonGroup>
      </GridItem>
    </>
  );
}
