import React from 'react';
import { EllureSession, GoToViewFunc, VIEWS } from '..';

import { useSession } from '@services/session';

import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  Grid,
  GridItem,
  Heading,
  Select,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useRadioGroup,
} from '@chakra-ui/react';
import { Picker, Swatch } from '@components';

import { hex2hsl, hsl2hex } from '@utils';
import SeasonMap from '../../../@data/seasons.json';

export default function ChooseShadeView(props: { goToView: GoToViewFunc; shade: string }) {
  const { session, updateSession } = useSession<EllureSession>();

  const [tab, setTab] = React.useState(0);
  const [season, setSeason] = React.useState<keyof typeof SeasonMap>('spring');
  const desktopScroller = React.useRef<HTMLDivElement>(null);
  const desktopSelectedShade = React.useRef<HTMLDivElement>(null);

  const [selectedShade, setSelectedShade] = React.useState(session.get('c') || SeasonMap.spring.shades[0]);
  const selectedHSL = hex2hsl(selectedShade);

  const shadesGroup = useRadioGroup({
    name: 'shade',
    defaultValue: selectedShade,
  });

  // UPDATE SHADE VALUES
  React.useEffect(() => {
    setSelectedShade(shadesGroup.value as string);
    updateSession({
      c: shadesGroup.value,
    });
  }, [shadesGroup.value]);

  return (
    <>
      <GridItem area='body' textAlign='center'>
        <Grid
          templateAreas={`"header" "picker"`}
          templateColumns={'100%'}
          templateRows={'max-content max-content'}
          gap='0 5vw'
          height='100%'
          flex='1'
        >
          <GridItem p={3} area='header' alignSelf={'stretch'}>
            <Heading as='h1'>Design your shade</Heading>
          </GridItem>
          <GridItem p={3} area='picker' alignSelf={'center'}>
            <Tabs variant='enclosed' isFitted index={tab} onChange={(i) => setTab(i)} height={'93%'}>
              <TabList>
                <Tab>Season shade</Tab>
                <Tab>Customize</Tab>
              </TabList>

              <TabPanels height={'100%'} border={'1px solid'} borderColor={'var(--chakra-colors-chakra-border-color)'}>
                <TabPanel height={'100%'} display='flex' flexDir={'column'} p={0}>
                  <FormControl px={4}>
                    <Text mt={3} mb={1}>
                      Season
                    </Text>
                    <Select value={season} onChange={(e) => setSeason(e.currentTarget.value as any)} borderRadius={0}>
                      <option value='spring'>Spring</option>
                      <option value='summer'>Summer</option>
                      <option value='autumn'>Autumn</option>
                      <option value='winter'>Winter</option>
                    </Select>
                  </FormControl>
                  <Text mt={3} mb={1} px={4}>
                    Shades
                  </Text>
                  <FormControl ref={desktopScroller} overflowY={'auto'} p={4} maxH={'400px'}>
                    <Flex flexWrap='wrap' gap={3}>
                      {SeasonMap[season].shades.map((shade: string, i: number) => {
                        const props = shadesGroup.getRadioProps({ value: shade });
                        const isChecked = shadesGroup.value === shade;
                        return (
                          <Flex
                            ref={isChecked ? desktopSelectedShade : null}
                            as='label'
                            htmlFor={props.id}
                            cursor='pointer'
                            flex='1 0 150px'
                            borderRadius='30px'
                            border='1px solid #ccc'
                            gap='10px'
                            pr='10px'
                            title={SeasonMap[season].names[i]}
                            borderTopRightRadius={0}
                            borderBottomRightRadius={0}
                          >
                            <Swatch key={i} shade={shade} size={60} height='60px' {...props} />
                            <Text alignSelf='center' fontSize='13px'>
                              {SeasonMap[season].names[i]}
                            </Text>
                          </Flex>
                        );
                      })}
                    </Flex>
                  </FormControl>
                </TabPanel>
                <TabPanel height={'100%'} overflowY='auto'>
                  <Picker
                    value={selectedHSL}
                    onChange={(hsl) => {
                      shadesGroup.setValue(hsl2hex(hsl[0], hsl[1], hsl[2]));
                    }}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </GridItem>
        </Grid>
      </GridItem>
      <GridItem area='nav' textAlign='center'>
        <ButtonGroup p={3} spacing={4}>
          <Button size='lg' variant={'outline'} background={'whiteAlpha.600'} onClick={props.goToView.bind(null, VIEWS.WELCOME)}>
            Back
          </Button>
          <Spacer />
          <Button size='lg' colorScheme='depend' onClick={props.goToView.bind(null, VIEWS.CONFIRM)}>
            Next
          </Button>
        </ButtonGroup>
      </GridItem>
    </>
  );
}
