import React from 'react';
import { Box, Flex, Spinner } from '@chakra-ui/react';

export const LayoutWrapper = React.forwardRef(
  (props: { style?: React.CSSProperties; children: React.ReactNode }, ref?: React.LegacyRef<HTMLDivElement>) => {
    return (
      <Flex
        ref={ref}
        height='100%'
        alignItems='center'
        justifyContent='center'
        style={props.style}
        className='wrapper'
        pos='relative'
      >
        {props.children}
      </Flex>
    );
  },
);

export function Loader(props: { children: React.ReactNode; isLoading: boolean; style?: React.CSSProperties }) {
  return (
    <Box style={props.style}>
      <Flex
        transition='0.25s'
        opacity={props.isLoading ? 1 : 0}
        pointerEvents={props.isLoading ? 'all' : 'none'}
        backgroundColor='#000'
        position='absolute'
        top='0'
        left='0'
        right='0'
        bottom='0'
        zIndex='10'
        alignItems='center'
        justifyContent='center'
      >
        <Spinner size='xl' color='#fff' />
      </Flex>
      {props.children}
    </Box>
  );
}
