export type HSL = [hue: number, saturation: number, lightness: number];
export type RGB = [r: number, g: number, b: number];

export function hsl2hex(h: number, s: number, l: number) {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0'); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

export function hex2hsl(hex: string): HSL {
  if (hex === '') return [0, 0, 0];
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) return [0, 0, 0];

  const r = parseInt(result[1], 16) / 255;
  const g = parseInt(result[2], 16) / 255;
  const b = parseInt(result[3], 16) / 255;
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = 0,
    s = 0,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    var d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return [h * 360, Math.round(s * 100), Math.round(l * 100)];
}

export function hex2rgb(hex: string, decimal = true): RGB | null {
  const components = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const multiplier = decimal ? 1 / 255 : 1;
  return components
    ? [
        parseInt(components[1], 16) * multiplier,
        parseInt(components[2], 16) * multiplier,
        parseInt(components[3], 16) * multiplier,
      ]
    : null;
}

/**
 *
 * @param hex hex value to convert to grayscale.
 * @returns grayscale value between 0 and 255.
 */
export function hex2grayscale(hex: string) {
  if (hex) {
    const matchColors = /#(\w{2})(\w{2})(\w{2})/;
    const match = matchColors.exec(hex);
    if (!match) return 1;

    const r = parseInt(match[1], 16);
    const g = parseInt(match[2], 16);
    const b = parseInt(match[3], 16);
    return Math.floor(0.2126 * r + 0.7152 * g + 0.0722 * b);
  }

  return 255;
}

export function isHex(hex: string) {
  return /^#([0-9A-F]){6}$/i.test(hex);
}
