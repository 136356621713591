import React from 'react';

import { HSL, hsl2hex, useMediaQuery } from '@utils';

import { Box, Button, ButtonGroup, Flex } from '@chakra-ui/react';
import { ColorSliderInput } from './color-slider';
import { ColorWheelInput } from './color-wheel';

import { RiSearch2Line, RiSettings2Line } from 'react-icons/ri';

export function Picker(props: { value: HSL; breakpoint?: string; onChange: (hsl: HSL) => void }) {
  const isLarge = useMediaQuery(props.breakpoint || '750px');
  const [tabIndex, setTabIndex] = React.useState(0);

  React.useEffect(() => {
    if (isLarge) setTabIndex(0);
  }, [isLarge]);

  const hsl = props.value;
  return (
    <Flex height='100%' flexDir='column' position='relative'>
      {tabIndex === 0 && (
        <Box
          position={{ base: 'absolute', web: 'relative' }}
          height={{ base: '50vw', web: 'auto' }}
          width={{ base: '100%', web: '90%' }}
          transform={{ base: 'translateY(-100%)', web: 'none' }}
          mx={'auto'}
        >
          <ColorWheelInput
            value={hsl[0]}
            onChange={(h) => props.onChange([h, hsl[1], hsl[2]])}
            hsl={hsl}
            style={{ flex: 1 }}
            breakpoint={props.breakpoint}
          />
        </Box>
      )}
      {(isLarge || (!isLarge && tabIndex === 1)) && (
        <Flex
          width='100%'
          flexDir='column'
          gap={{ base: 3, web: 5 }}
          px={{ base: 5, web: 0 }}
          py={{ base: 2, web: 4 }}
          position={{ base: 'absolute', web: 'relative' }}
          transform={{ base: 'translateY(-100%)', web: 'none' }}
          backgroundColor={{ base: 'white', web: 'transparent' }}
        >
          <ColorSliderInput
            showLabel={isLarge}
            value={hsl[1]}
            onChange={(s) => props.onChange([hsl[0], s, hsl[2]])}
            hsl={hsl}
            label='Tone'
            domain={[0, 100, 1]}
            colorKeys={[hsl2hex(hsl[0], 0, hsl[2]), hsl2hex(hsl[0], 50, hsl[2]), hsl2hex(hsl[0], 100, hsl[2])]}
          />
          <ColorSliderInput
            showLabel={isLarge}
            value={hsl[2]}
            onChange={(l) => props.onChange([hsl[0], hsl[1], l])}
            hsl={hsl}
            label='Brightness'
            domain={[15, 85, 1]}
            colorKeys={[hsl2hex(hsl[0], hsl[1], 15), hsl2hex(hsl[0], hsl[1], 50), hsl2hex(hsl[0], hsl[1], 85)]}
          />
        </Flex>
      )}
      {!isLarge && (
        <ButtonGroup width='100%' spacing={2} padding={1}>
          <Button leftIcon={<RiSearch2Line />} size='sm' w='100%' onClick={setTabIndex.bind(null, 0)}>
            Search
          </Button>
          <Button leftIcon={<RiSettings2Line />} size='sm' w='100%' onClick={setTabIndex.bind(null, 1)}>
            Adjust
          </Button>
        </ButtonGroup>
      )}
    </Flex>
  );
}
