import React from 'react';
import { VIEWS, GoToViewFunc, DependSession } from '..';

import { useSession } from '@services/session';
import { isHex, hex2hsl } from '@utils';

import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
} from '@chakra-ui/react';
import { LayoutWrapper, Swatch } from '@components';

const logo = require('@assets/media/lilly-nails-logo.png');

export default function EndView(props: { goToView: GoToViewFunc }) {
  const { updateSession } = useSession<DependSession>();
  const [HEX, setHEX] = React.useState({ touched: false, isValid: false, value: '' });

  const next = React.useCallback(() => {
    if (!HEX.isValid) {
      setHEX({ touched: true, isValid: false, value: HEX.value });
      return;
    }

    const hsl = hex2hsl(HEX.value);

    updateSession({
      s: VIEWS.CONFIRM,
      c: HEX.value,
      l: hsl[2],
      skip: true,
    });
    props.goToView(VIEWS.CONFIRM);
  }, [HEX, props.goToView, updateSession]);

  return (
    <LayoutWrapper
      style={{
        background:
          'linear-gradient(0deg, rgba(217,104,104,0.2) 0%, rgba(231,156,156,0.15) 15%, rgba(245,208,208,0.10) 35%, rgba(255,255,255,0) 50%)',
      }}
    >
      <Grid templateAreas={`"body"`} templateColumns={'100%'} templateRows={'1fr'} gap='0 5vw' height='100%' flex='1'>
        <GridItem area='body' textAlign='center' alignSelf={'center'} justifySelf='center'>
          <Flex flexDir='column' alignItems={'center'} gap={6} maxWidth={'600px'}>
            <Image src={logo} alt='Lilly Nails' width={'100px'} />
            <Heading as='h1'>What is your color code?</Heading>
            <Flex flexDir='column'>
              <Text mb={6}>
                <b>Expected shade</b>
              </Text>
              <Swatch shade={HEX.isValid ? HEX.value : 'grey'} isChecked={HEX.isValid} width='140px' />
            </Flex>
            <FormControl maxW={'200px'} isRequired isInvalid={HEX.touched && !HEX.isValid}>
              <FormLabel>Color code</FormLabel>
              <InputGroup>
                <InputLeftAddon>#</InputLeftAddon>
                <Input
                  value={HEX.value.replace('#', '')}
                  background={'whiteAlpha.900'}
                  onChange={(e) => {
                    const value = '#' + e.target.value.replace('#', '');
                    setHEX({ touched: true, isValid: isHex(value), value });
                  }}
                />
              </InputGroup>
              <FormErrorMessage>Not a valid color code.</FormErrorMessage>
            </FormControl>
            <Button size='lg' colorScheme='depend' isDisabled={!HEX.isValid} onClick={next}>
              Next
            </Button>
          </Flex>
          <Divider my={6} />
          <Button
            size='lg'
            background={'whiteAlpha.600'}
            variant={'outline'}
            onClick={props.goToView.bind(null, VIEWS.WELCOME)}
          >
            Back
          </Button>
        </GridItem>
      </Grid>
    </LayoutWrapper>
  );
}
