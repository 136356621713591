import axios, { AxiosInstance } from 'axios';

type KeysWithValsOfType<T, V> = keyof { [P in keyof T as T[P] extends V ? P : never]: P };

interface IRequest<body, query, params, headers> {
  body: body;
  query: query;
  params: params;
  headers?: headers;
}
type Request<body, query, params, headers> = Omit<
  IRequest<body, query, params, headers>,
  KeysWithValsOfType<IRequest<body, query, params, headers>, never>
>;
type Response<ResultData> = Promise<{ data: ResultData; status: number }>;

type Endpoint = string | string[];

interface IRequestOptions {
  endpoint: Endpoint;
  transformRequest?: ((body: any) => any)[];
}

export type CLIENTAPIConfig = { baseURL?: string };

class CLIENTAPI {
  public routes = {
    public: 'public',
    register: 'api/register',
    algorithm: 'api/algorithm',
    auth: 'auth',
  };

  public server: AxiosInstance;

  constructor(config: CLIENTAPIConfig) {
    this.server = axios.create({
      baseURL: config.baseURL,
      responseType: 'json',
    });
  }

  private parseEndpoint(endpoint: Endpoint, params?: { [key: string]: string }) {
    let uri = typeof endpoint === 'string' ? endpoint : endpoint.join('/');
    if (params)
      uri = Object.entries(params).reduce((uri, [key, value]) => {
        const searchValue = new RegExp(`:${key}`, 'g');
        return uri.replace(searchValue, value);
      }, uri);

    return '/' + uri;
  }

  private executeRequest = (method: string, endpoint: Endpoint, { params, body, query, headers }: any) => {
    return this.server({
      method,
      url: this.parseEndpoint(endpoint, params),
      data: body,
      params: query,
      headers,
    });
  };

  public GET = <query = never, params extends { [key: string]: string } = never, ResultData = never>(
    options: IRequestOptions,
  ) => {
    const executeRequest = this.executeRequest;
    return async function (req: Request<never, query, params, { [header: string]: string }>): Response<ResultData> {
      const response = await executeRequest('GET', options.endpoint, req);
      return { data: response.data, status: response.status };
    };
  };

  public POST = <body = never, query = never, params extends { [key: string]: string } = never, ResultData = never>(
    options: IRequestOptions,
  ) => {
    const executeRequest = this.executeRequest;
    return async function (req: Request<body, query, params, { [header: string]: string }>): Response<ResultData> {
      if (options.transformRequest) {
        for (const transform of options.transformRequest) {
          (req as any).body = transform((req as any).body);
        }
      }

      const response = await executeRequest('POST', options.endpoint, req);
      return { data: response.data, status: response.status };
    };
  };

  public PATCH = <body = never, query = never, params extends { [key: string]: string } = never, ResultData = never>(
    options: IRequestOptions,
  ) => {
    const executeRequest = this.executeRequest;
    return async function (req: Request<body, query, params, { [header: string]: string }>): Response<ResultData> {
      const response = await executeRequest('PATCH', options.endpoint, req);
      return { data: response.data, status: response.status };
    };
  };

  public DELETE = <query = never, params extends { [key: string]: string } = never, ResultData = never>(
    options: IRequestOptions,
  ) => {
    const executeRequest = this.executeRequest;
    return async function (req: Request<never, query, params, { [header: string]: string }>): Response<ResultData> {
      const response = await executeRequest('DELETE', options.endpoint, req);
      return { data: response.data, status: response.status };
    };
  };
}

export { CLIENTAPI };
